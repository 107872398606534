import fastdom from "fastdom";

export function slideInOrOut(node: HTMLElement, direction: string): void {
  const nodeHeight = node.clientHeight;
  node.style.transition = "all 0.5s ease-in-out";

  fastdom.mutate(() => {
    if (direction === "out") {
      node.style.bottom = `-${nodeHeight}px`;
      node.style.opacity = "0";
      node.style.zIndex = "-1";
    } else {
      node.style.zIndex = "9999";
      node.style.bottom = "0px";
      node.style.opacity = "1";
    }
  });
}
