import queryAll, { query } from "../dom-helpers/query";
import initPrime3Footer from "././prime3-footer";
import initPreviewFooter from "./preview-footer";
import "./preview-footer.scss";
import "./prime3-footer.scss";
import "./promo-footer.scss";
import { updateHost } from "./utils";

export default function initFooter(): void {
  const footer = query("#StandardFooter") as HTMLElement;
  if (footer && footer.dataset.footer === "prime3") {
    fetch(`/features.json`)
      .then(response => {
        if (response.status === 200) {
          response.json().then(features => {
            if (features.prime3) {
              initPrime3Footer();
            }
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  const footerEl = queryAll("[id$='-footer_shorthand_link']");
  if (footerEl) {
    updateHost(footerEl);
  }

  const PreviewFooterEl = queryAll("#PreviewFooter");
  if (PreviewFooterEl) {
    return initPreviewFooter();
  }
}
