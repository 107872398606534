import { query } from "../dom-helpers/query";

const p3nodes = {
  footer: query("#StandardFooter") as HTMLElement,
  topLink: query("[id$='-footerTopLink']") as HTMLElement,
  logo: query("#FooterLogo") as HTMLElement,
  logoLink: query("[id$='-footer_shorthand_link']") as HTMLElement,
  lower: document.createElement("div") as HTMLElement,
  lowerInner: document.createElement("div") as HTMLElement,
};

export default function initPrime3Footer(): void {
  p3nodes.footer.classList.add("Prime3");
  p3nodes.logo.setAttribute("src", p3nodes.logo.getAttribute("src").replace("black", "white"));
  p3nodes.topLink.classList.add("Prime3");
  p3nodes.logoLink.classList.add("Prime3");

  p3nodes.lower.id = "prime3FooterLower";
  p3nodes.lower.className = "prime3FooterLower";

  p3nodes.lowerInner.id = "prime3FooterLowerInner";
  p3nodes.lowerInner.className = "prime3FooterLowerInner";
  const href = p3nodes.logoLink.getAttribute("href");

  const lowerItems = [
    "<div>Join the world's leading storytelling teams at Shorthand.</div>",
    // eslint-disable-next-line no-secrets/no-secrets
    "<div><a href=" + href + "><u>TRY IT FOR <strong>FREE</strong></u></a></div>",
    "<div>No code, no credit card, & no commitment required.</div>",
  ];
  p3nodes.lowerInner.innerHTML = "<div class='Layout prime3-items'>" + lowerItems.map(item => item).join("") + "</div>";

  p3nodes.footer.after(p3nodes.lower);
  p3nodes.lower.innerHTML = p3nodes.lowerInner.outerHTML;
}
